import React, { useState } from 'react'
import GoTrue from 'gotrue-js'

import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Backdrop,
  Snackbar,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const auth = new GoTrue({
  APIUrl: 'https://rise.trinet.com/.netlify/identity',
  setCookie: true,
})

let token = ''
if (typeof window !== 'undefined') {
  const urlParams = new URLSearchParams(window.location.search)
  token = urlParams.get('token')
}

const Invite = () => {
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ isError: false })

  const handleAccept = (evt) => {
    evt.preventDefault()
    setLoading(true)
    auth
      .acceptInvite(token, password, true)
      .then((user) => {
        setLoading(false)
        if (
          user.app_metadata.roles
          && (user.app_metadata.roles.includes('admin')
            || user.app_metadata.roles.includes('content-creator'))
        ) {
          window.location.href = '/admin'
        } else {
          setError({
            isError: true,
            message:
              'Publisher Role not set. Please contact support. Redirecting to Publisher Dashboard...',
          })
          setTimeout(() => {
            window.location.href = '/admin'
          }, 10000)
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        setLoading(false)
        setError({
          isError: true,
          message:
            err.json && err.json.error_description
              ? err.json.error_description
              : 'Verification error, please try again.',
        })
      })
  }

  const handleChange = (evt) => {
    const { value } = evt.target
    setPassword(value)
  }

  const handleClose = () => {
    setError({ isError: false })
  }

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Snackbar open={error.isError} onClose={handleClose}>
        <Alert variant="filled" severity="error">
          {error.message}
        </Alert>
      </Snackbar>
      <Backdrop open={loading}>
        <CircularProgress />
      </Backdrop>
      <Box component="form" minWidth="350px" onSubmit={handleAccept}>
        <Box mb={3}>
          <TextField
            label="Create Password"
            name="password"
            value={password}
            type="password"
            onChange={handleChange}
            required
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            width="100%"
          >
            Accept
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Invite
